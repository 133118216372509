import axios from './axios_api'

//load all report data
export const loadReportData = async payload => {
	return await axios.post('analytics/report/transaction/list', payload)
}

//load report type list
export const loadReportTypeList = async payload => {
	return await axios.post('analytics/report/access/list/v1', payload)
}
export const loadReportTypeLists = async payload => {
	return await axios.post('analytics/report/type/master/list', payload)
}
//load report tag type filter list
export const loadReportFilterList = async payload => {
	return await axios.post('analytics/widget/tag_type/filter/list', payload)
}

//load reports process type list
export const loadReportProcessTypeList = async payload => {
	return await axios.post('admin/category/tagtype/list', payload)
}

//add a report
export const addReport = async payload => {
	return await axios.post('/report/add', payload)
}

export const updateDownloadCount = async payload => {
	return await axios.post('/update/report/download/count', payload)
}
