export const TableStyle = {
	// style object for base or default style
	variants: {
		stickyHeaders: {
			table: {},
			th: {
				bg: '#4daf89',
				color: 'white',
				// position: '-webkit-sticky',
				position: 'sticky',
				top: '0px',
				zIndex: '9',
				borderRight: '1px solid white',
			},
			td: {
				border: '10px solid #f5f0f0',
				borderRight: '0px',
				borderLeft: '0px',
			},
		},
		tooltipTable: {
			table: {},
			th: {},
			td: {
				borderBottom: '1px solid #c9c9c9',
			},
		},
	},
	// default values for 'size', 'variant' and 'colorScheme'
	defaultProps: {},
}
