import axios from './axios_api'

// MYQUEUE, DELAYED, MENTIONS
export const loadActivityListV1 = async payload => {
	return await axios.post('activity/queue/list/all/v1', payload)
}
export const loadActivityListMeeting = async payload => {
	return await axios.post('activity/asset/mappings/meetings/list', payload)
}
// MYFOCUS
export const loadFocusActivityList = async payload => {
	return await axios.post('activity/asset/focus-list/v1', payload)
}

// UNASSIGNED ACTIVITY LIST (FLV = 4 /FLD = 2 / NFL = 5)
export const loadUnassignedActivityList = async payload => {
	return await axios.post('activity/my_queue/list', payload)
}

// QUEUE SPECIFIC ACITVITY LIST
export const loadActivityListV2 = async payload => {
	return await axios.post('activity/queue/list/all/v2', payload)
}

export const loadUnassignedQueueList = async payload => {
	return await axios.post('activity/unassigned/queue/list', payload)
}

// Workflow specific activity list
export const loadFilterActivityList = async payload => {
	let searchUrl = 'v1'
	if (payload.isElasticSearchEnabled) {
		searchUrl = 'v2'
	}
	return await axios.post(
		`workflow_reference/activity_type/search/${searchUrl}`,
		payload
	)
}

// Load workflow timeline list
export const loadTimelineList = async payload => {
	return await axios.post('activity/timeline/list', payload)
}

// Add timeline update
export const addTimelineUpdate = async payload => {
	return await axios.post('activity/timeline/entry/add', payload)
}

// Load activity participant
export const loadActivityParticipant = async payload => {
	return await axios.post('activity/participant/list', payload)
}
// Load email domain list
export const loadDomainList = async payload => {
	return await axios.post('email/asset/domain/list', payload)
}
// Load email thread  list
export const loadEmailThredList = async payload => {
	return await axios.post('email/thread/list/select', payload)
}
// Load email thread  list
export const loadEmailThredMap = async payload => {
	return await axios.post('email/thread/mapping/select', payload)
}
// Load activity participant
export const setParticipantAsOwner = async payload => {
	return await axios.post('activity/owner/flag/set', payload)
}
// Set participant as lead
export const setParticipantAsLead = async payload => {
	return await axios.post('activity/lead/update', payload)
}
// Load activity participant
export const removeParticipant = async payload => {
	return await axios.post('activity/participant/access/reset', payload)
}
// Load activity participant
export const inviteParticipant = async payload => {
	return await axios.post('activity/participant/access/set', payload)
}
// Load activity participant
export const searchOrgContacts = async payload => {
	return await axios.post('admin/organization/coworker_contact/list', payload)
}
// Load reference files for an activity
export const loadReferenceList = async payload => {
	return await axios.post(
		'activity-activity/mapping/child-activities/list',
		payload
	)
}
// Load reference files for an activity
export const loadReferenceListByTagType = async payload => {
	return await axios.post(
		'activity-activity/mapping/child-activities/list/v1',
		payload
	)
}
// Load Reference filters
export const loadReferenceFilters = async payload => {
	return await axios.post(
		'activity-activity/mapping/child-activities/list/v1',
		payload
	)
}

// Load Previous Status List
export const loadPreviousStatusList = async payload => {
	return await axios.post('activity/previous/status/list', payload)
}

// Alter Activity Status
export const alterActivityStatus = async payload => {
	return await axios.post('activity/status/alter', payload)
}

// Load Activity Details
export const loadActivityDetails = async payload => {
	return await axios.post('activity/details/list', payload)
}

export const loadGetFormRateLimit = async payload => {
	return await axios.post('verify/activity/type/rate/limit', payload)
}

export const loadActivityReceiverEmailId = async payload => {
	return await axios.post('activity/list/field/prefill', payload)
}

// Load Activity ARP Details
export const loadActivityTypeDetails = async payload => {
	return await axios.post('/admin/activity_type/entry', payload)
}

//LOAD FORM FIELDS
export const loadFormFieldsList = async payload => {
	return await axios.post('form/access/global/entry/collection', payload)
}

//Add Activity
export const addActivity = async payload => {
	return await axios.post('activity/add/v1', payload)
}
// Load status where is use participating in
/**
 * @params :
 *  flag = 0 -> MyQueue
 *  flag = 1 -> Unread
 *  flag = 2 -> Mentions
 *  flag = 3 -> Focus
 */
export const LoadStatusAndActivityType = async payload => {
	return await axios.post('activity/asset/participating/status/list', payload)
}

// load activity types for global use
export const loadActivityTypeList = async payload => {
	return await axios.post('admin/workforce/activity_type/list', payload)
}

// load activity status for global
export const loadActivityStatusList = async payload => {
	return await axios.post('admin/workforce/activity_status/list', payload)
}

// add external guests
export const addGuests = async payload => {
	return await axios.post('bot/participant/add/email', payload)
}

//load workforce level forms restricted to user
export const loadWorkforceLevelForms = async payload => {
	return await axios.post('workforce/activity_type/search', payload)
}
//load edc field id
export const loadEDCFieldId = async payload => {
	return await axios.post('retrieve/edc/transaction', payload)
}

//alter file cover
export const alterFileCover = async payload => {
	return await axios.post('activity/cover/alter', payload)
}

// Load Child orders list
export const loadChildOrderActivityList = async payload => {
	return await axios.post('activity/workflow/child_orders/list', payload)
}

//Load Activity based on activity_type_category_id
export const loadSelectCategory = async payload => {
	return await axios.post('activity/list/select/category/v1', payload)
}
//Load Activity based on activity_type_category_id
export const loadReferredActivity = async payload => {
	return await axios.post('activity/list/referred/activity', payload)
}

// Export Timeline
export const exportTimeLine = async payload => {
	return await axios.post('datamanagement/export', payload)
}

//Submit as Draft
export const submitAsDraft = async payload => {
	return await axios.post('draft/form/add', payload)
}

//Alter Draft
export const alterDraft = async payload => {
	return await axios.post('draft/form/alter', payload)
}

//Archive Draft
export const archiveDraft = async payload => {
	return await axios.post('draft/form/delete', payload)
}

//load bulk summary
export const activityBulkSummary = async payload => {
	return await axios.post('activity/bulk-summary/list/v1', payload)
}
export const emailBulkSummary = async payload => {
	return await axios.post('activity/email-summary', payload)
}

//Bulk Edit
export const bulkEditForm = async payload => {
	return await axios.post('form/activity/alter/bulk', payload)
}

// meeting Enabled
export const meetingEnable = async payload => {
	return await axios.post('form/entity/mapping/category/select', payload)
}

//chat functionality

// Load activity participant
export const inviteChatParticipant = async payload => {
	return await axios.post('activity/participant/access/set', payload)
}

export const resetChatCount = async payload => {
	return await axios.post('activity/unread/count/reset', payload)
}

export const chatMsgList = async payload => {
	return await axios.post('activity/timeline/list', payload)
}

export const addNewMsg = async payload => {
	return await axios.post('activity/timeline/entry/add', payload)
}

export const getOrgConfigList = async payload => {
	return await axios.post('admin/organization/ai_bot/config/list', payload)
}

export const getAccessSelectActivity = async payload => {
	return await axios.post(
		'admin/asset_type/access/mapping/select/activity_type',
		payload
	)
}

//Parent Activity update
export const addParentActivity = async payload => {
	return await axios.post('/activity/map/parent/update', payload)
}

export const accountTitleDupCheck = async payload => {
	return await axios.post('/activity/title/edit/dedupe', payload)
}

export const getGanntChildActivity = async payload => {
	return await axios.post('/activity/gantt-chart/child-orders/list', payload)
}

export const setActivityReference = async payload => {
	return await axios.post('/activity/reference/add', payload)
}

export const updateActivityReference = async payload => {
	return await axios.post('/activity/reference/update', payload)
}

export const deleteActivityReference = async payload => {
	return await axios.post('/activity/reference/delete', payload)
}

export const updateActivityDueDates = async payload => {
	return await axios.post('/bot/set/parent/child/due/date/v2', payload)
}

export const updateActivityProgress = async payload => {
	return await axios.post('/activity/percentage/completion/update', payload)
}

export const checkActivityManager = async payload => {
	return await axios.post('/activity/asset/mapping/select/manager', payload)
}

export const setPriority = async payload => {
	return await axios.post('/activity/list/update/priority', payload)
}

export const updateActivityMasterData = async payload => {
	return await axios.post('/activity/details/master/data/update', payload)
}

export const notificationAsset = async payload => {
	return await axios.post(
		'/activity/asset/mapping/update/notification/muted',
		payload
	)
}

export const updateCancelRequest = async payload => {
	return await axios.post('/bot_step/cancelled/status/alter', payload)
}

export const declineHistory = async payload => {
	return await axios.post('/activity/timeline/decline/history/list', payload)
}

// Remove participant as lead
export const removeParticipantAsLead = async payload => {
	return await axios.post('activity/lead/update/v3', payload)
}

// Load activity participant
export const loadWorkflowParticipant = async payload => {
	return await axios.post('activity/participants/list/v1', payload)
}

export const timelineFilterOptions = async payload => {
	return await axios.post('timeline/common/filter/list', payload)
}
export const editfieldConfigs = async payload => {
	return await axios.post('workforce/form/mapping/header/field/select', payload)
}
