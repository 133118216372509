import axios from './axios_api'

// Listing of Tag types & Origin Forms
export const loadFormTagMappingList = async payload => {
	return await axios.post('form/entity/mapping/tag/list', payload)
}

export const loadFormTagMappingListForDashboard = async payload => {
	return await axios.post('form/entity/mapping/dashboard/form/list', payload)
}

// listing of field for selected form
export const loadFormFieldList = async payload => {
	return await axios.post('form/access/global/entry/collection', payload)
}
// api to get all form data for given form id
export const loadAllFormData = async payload => {
	return await axios.post('activity/form/data/select', payload)
}

// load workflow ref
export const loadWorkflowRef = async payload => {
	let searchUrl = 'v1'
	if (payload.isElasticSearchEnabled) {
		searchUrl = 'v2'
	}
	return await axios.post(
		`workflow_reference/activity_type/search/${searchUrl}`,
		payload
	)
}

// load workflow ref
export const loadRoleAssetRef = async payload => {
	return await axios.post('activity/asset-role-reference/list', payload)
}

// load workflow ref
export const loadAssetRef = async payload => {
	return await axios.post('activity/asset-reference/list', payload)
}

// load workflow ref
export const loadExcelBotValidation = async payload => {
	return await axios.post('form/field/bot/validation', payload)
}

// load product cart categories
export const loadProductCategories = async payload => {
	return await axios.post('admin/tag_type/mappings/list', payload)
}

// load product workflow types
export const loadProductWorkflowTypes = async payload => {
	return await axios.post('admin/tag/entity-mappings/list', payload)
}

// load product workflow
export const loadProductWorkflow = async payload => {
	return await axios.post('workflow/activity-reference/list', payload)
}

// load workflow variants
export const loadProductVariants = async payload => {
	return await axios.post('activity/workflow/child-products/list', payload)
}
// validate duplication of account name
export const validateGroupAccountName = async payload => {
	return await axios.post('activity/group-account-name/bot', payload)
}
// validate duplication of account code
export const validateGroupAccountCode = async payload => {
	return await axios.post('activity/account-code/bot', payload)
}

// load composite lov state
export const loadCompositLovData = async payload => {
	return await axios.post('admin/lov-datatype/list/v1', payload)
}

// on form save for activity save
export const addActivity = async payload => {
	return await axios.post('activity/add/v1', payload)
}

//get account/workflow search -

export const loadWorkflowSearchData = async payload => {
	return await axios.post('workflow_reference/activity_type/search/v1', payload)
}

// load the submitted form for an activity
export const loadSubmittedFormList = async payload => {
	return await axios.post('activity/timeline/form/list', payload)
}

// load the form list based on provided status.
export const loadStatusBasedFormList = async payload => {
	return await axios.post('form/access/status/list/v1', payload)
}

//load asset level form list
export const loadAssetLevelFormList = async payload => {
	return await axios.post('workflow/asset_level/forms/list', payload)
}

//load the workflow forms
export const loadAllWorkFlowForms = async payload => {
	return await axios.post('form/access/list', payload)
}

// load status list of an activity
export const loadActivityStatusList = async payload => {
	return await axios.post('activity_status/mapping/activity_type/list', payload)
}
// load sub status list
export const loadSubStatusList = async payload => {
	return await axios.post('activity/sub_status/list', payload)
}

//load field values with data
export const loadFieldValues = async payload => {
	return await axios.post('activity/form/validation/data', payload)
}

//update field data
export const fieldEditSave = async payload => {
	return await axios.post('form/activity/alter', payload)
}
/// Online List dependent List
export const loadOnlineDependentList = async payload => {
	return await axios.post('/admin/lov/dependent/list', payload)
}
export const loadfetchListforRefrence = async payload => {
	return await axios.post('/admin/lov-datatype/list', payload)
}
export const loadExcelFieldValidation = async payload => {
	return await axios.post('/form/field/bot/validation', payload)
}
export const loadFormAttachments = async payload => {
	return await axios.post('/activity/form/download/attachements', payload)
}

// Save Document repository
export const saveDocumentRepository = async payload => {
	return await axios.post('/document/add', payload)
}

// Get autopopulated field values
export const loadAutopopulateValues = async payload => {
	return await axios.post('form/auto-populate/list', payload)
}

// Add form participant
export const addFormParticipant = async payload => {
	return await axios.post('form/participant/set', payload)
}

// Remove form participant
export const removeFormParticipant = async payload => {
	return await axios.post('form/participant/reset', payload)
}

//Load Draft List
export const loadDraftFormList = async payload => {
	return await axios.post('draft/form/list', payload)
}

//Shorten URL
export const shortenUrl = async payload => {
	return await axios.post('url/parameters/shorten/v1', payload)
}

//Send Email
export const sendEmail = async payload => {
	const { outlook_email_integration } = payload
	console.log(outlook_email_integration, 'outlook_email_integration')
	let endpointUrl =
		outlook_email_integration === 3 ? 'send/email/v9' : 'send/email/v8'
	return await axios.post(endpointUrl, payload)
}

export const workforceMappedForms = async payload => {
	return await axios.post('workforce/form/mapping/list', payload)
}

export const loadFieldHistory = async payload => {
	return await axios.post('form/fields/history/v1', payload)
}

export const validateAnnexExcel = async payload => {
	return await axios.post('process/annexure', payload)
}

export const formTransactionCheck = async payload => {
	return await axios.post('activity/form_transaction/check', payload)
}

//roms caf and crf config
export const isCAfCrfActivity = async payload => {
	return await axios.post('admin/roms/config/list', payload)
}

//excel json list
export const loadExcelJson = async payload => {
	return await axios.post('s3/excel_json/list', payload)
}

// get customerCompanySeal
export const loadCustomerCompanySeal = async payload => {
	return await axios.post('/activity/form_transaction/check/v1', payload)
}

export const loadAutopopulateLovList = async payload => {
	return await axios.post('/field/auto-populate/list', payload)
}

export const loadOnlineMultiDependentList = async payload => {
	return await axios.post('/admin/lov/multi/dependent/list', payload)
}

export const loadDynamicFieldsList = async payload => {
	return await axios.post('/admin/lov/list/select/dynamic', payload)
}

export const loadFormDynamicFieldList = async payload => {
	return await axios.post(
		'/workforce/form/field/mapping/form/action/select',
		payload
	)
}

export const userIdValidation = async payload => {
	return await axios.post('/ethernet/subscriber/checkUniqueCustomer', payload)
}

export const loadfetchDynamicLovList = async payload => {
	return await axios.post('/ethernet/getDropdownList', payload)
}

export const sendSMS = async payload => {
	return await axios.post('/account/send/sms', payload)
}

export const regenerateCafView = async payload => {
	return await axios.post('/vodafone/process/timeline/entry/add', payload)
}

export const PaymentChanelData = async payload => {
	return await axios.post('/field/auto-populate/payment/channel/data', payload)
}

export const getSignature = async payload => {
	return await axios.post('/pam/payment/getSignature', payload)
}

export const createOrder = async payload => {
	return await axios.post('/pam/payment/createOrder', payload)
}

export const gatewayrouting = async payload => {
	return await axios.post('/pam/payment/gatewayrouting', payload)
}

export const paymentStatusCheck = async payload => {
	return await axios.post('/pam/payment/statuscheck', payload)
}
export const workDedupeBotCuidCheck = async payload => {
	return await axios.post('dedupe/bot/check/cuids', payload)
}
