import { Auth } from 'aws-amplify'
import axios from './axios_api'

export const cognitoSignIn = async ({ email, countryCode, mobile }) => {
  const username = !!email ? email : `${countryCode}${mobile}`
  return await Auth.signIn(username)
}

export const verifyCognitoChallenge = async ({ user, pwd }) => {
  return await Auth.sendCustomChallengeAnswer(user, pwd)
}

export const cognitoSignOut = async () => {
  return await Auth.signOut()
}

export const cognitoValidateOtp = async ({ user, otp }) => {
  let res = await Auth.sendCustomChallengeAnswer(user, otp)
  return res.getSignInUserSession()
}

//Update Last Seen
export const updateLastSeen = async payload => {
  return await axios.post('asset/list/update/last_seen_date_time', payload)
}
