import axios from './axios_api';
export const fetchBroadcastMessage = async payload => {
  return await axios.post('/admin/asset/broadcastlist', payload);
};

export const updateBroadcastReadStatus = async payload => {
  return await axios.post('/admin/broadcast/updateflag', payload);
};

export const fetchBroadcastMessageCount = async payload => {
  return await axios.post('/asset/broadcast/counts', payload);
};
