import axios from './axios_api'

export const fetchApplications = async payload => {
  return await axios.post('/analytics/application/list', payload)
}

export const fetchFilters = async payload => {
  return await axios.post('/analytics/widget/tag_type/filter/list', payload)
}

export const fetchFilterOptions = async payload => {
  //return await axios.post('/asset/access/levels/list/v2', payload)
   return await axios.post('/get/asset/dashboard/access', payload)

}

export const fetchWorkEfficiencyOptions = async payload => {
  return await axios.post('/arp/filters/data', payload)
}

export const fetchTBCreatorFilterOptions = async payload => {
  return await axios.post('/asset/individual/targets/select', payload)
}

export const fetchVeriticalFilterOptions = async payload => {
  return await axios.post('/retrieve/filter/data', payload)
}

export const fetchActivityCategory = async payload => {
  return await axios.post('/activity/category/search/list', payload)
}

export const fetchSIPTopDown = async payload => {
  return await axios.post('/tasi/entity/target/mapping/select/v1', payload)
}

export const insertTarget = async payload => {
  return await axios.post('/tasi/entity/target/mapping/insert/v1', payload)
}

export const updateTarget = async payload => {
  return await axios.post('/tasi/entity/target/mapping/update/v1', payload)
}

export const updateOutlier = async payload => {
  return await axios.post(
    '/tasi/entity/target/mapping/outlier/flag/update',
    payload
  )
}

export const checkIsFreeze = async payload => {
  return await axios.post('/tasi/entity/target/setting/select/freeze', payload)
}

export const targetSetting = async payload => {
  return await axios.post('/tasi/account/target/setting/list', payload)
}

export const accountsView = async payload => {
  return await axios.post('/account/view/summary', payload)
}

export const fetchTranslations = async payload => {
  return await axios.post('/language/app/content/mapping/select', payload)
}

export const fetchAccounts = async payload => {
  return await axios.post('/asset/mapping/category/targets/select', payload)
}

export const fetchKpiCodesByReportees = async payload => {
  return await axios.post('/widget/type/role/mapping/asset/list', payload)
}

export const fetchAccCodesByReportees = async payload => {
  return await axios.post('/asset/account/mapped/list', payload)
}

export const checkAccountChannelLevelTarget = async payload => {
  return await axios.post('/account/target/settings/asset/list', payload)
}

export const fetchWidgets = async payload => {
  return await axios.post('/analytics/management/widget/list', payload)
}
export const fetchSMEDrillDownData = async payload => {
  return await axios.post(
    '/analytics/asset/summary/transaction/manager/select',
    payload
  )
}
export const fetchWidgetData = async payload => {
  return await axios.post('/analytics/management/widget/value/v1', payload)
}

export const fetchWidgetDrillDownHeader = async payload => {
  return await axios.post('/analytics/drilldown/mapping/list', payload)
}

export const fetchWidgetDrillDownData = async payload => {
  return await axios.post('/analytics/management/widget/drilldown/v1', payload)
}

export const fetchSaSummaryDrillDownData = async payload => {
  return await axios.post('/analytics/management/widget/drilldown/sa', payload)
}

export const fetchSASummaryReportees = async payload => {
  return await axios.post(
    '/analytics/management/widget/value/resource/vertical',
    payload
  )
}

export const fetchSaSummaryResource = async payload => {
  return await axios.post('analytics/management/widget/value/resource', payload)
}

export const fetchReportessSMEScore = async payload => {
  return await axios.post(
    'analytics/asset/summary/transaction/manager/select',
    payload
  )
}

export const fetchAssetReportees = async payload => {
  return await axios.post('/admin/manger/assets/list/V1', payload)
}

export const fetchDashboardSIPPayoutReportees = async payload => {
  return await axios.post('/tasi/sip/asset/manager/select', payload)
}

export const fetchPeriodicSummary = async payload => {
  return await axios.post('/tasi/sip/payout/report/penutil/psummary/select', payload)
}

export const fetchManagerDetails = async payload => {
  return await axios.post('/asset/inline/collection/v2', payload)
}

export const fetchManagerResources = async payload => {
  return await axios.post('/asset/mananger/resources/list', payload)
}

export const fetchChannelDetails = async payload => {
  return await axios.post('/sip/asset/account/channel/flag', payload)
}

export const fetchSIPWidgets = async payload => {
  return await axios.post('/get/sip/widgets', payload)
}

export const fetchSIPDynamicWidgets = async payload => {
  return await axios.post('/tasi/sip/dynamic/report/select', payload)
}

export const fetchSIPPayoutReportees = async payload => {
  return await axios.post('/get/sip/reportee/data', payload)
}

export const fetchSIPLeaderBoards = async payload => {
  return await axios.post('/tasi/sip/payout/report/penutil/leaderboard/select', payload)
}

export const fetchSIPPayoutWidgetData = async payload => {
  return await axios.post('/tasi/sip/dynamic/report/select', payload)
}

export const fetchSIPPayoutTargets = async payload => {
  return await axios.post('/tasi/sip/dynamic/report/target/select', payload)
}

export const fetchDottedLineManagerAccess = async payload => {
  return await axios.post('/dottedmanager/access/account/check', payload)
}

export const fetchKPIProducts = async payload => {
  return await axios.post('/tasi/lov/product_list/select', payload)
}

export const fetchKPICategories = async payload => {
  return await axios.post('/tasi/widget/type/category/select', payload)
}

export const fetchKPITypes = async payload => {
  return await axios.post('/tasi/widget/type/master/sip/list', payload)
}

export const fetchReportees = async payload => {
  return await axios.post('/asset/list/selected/manager', payload)
}

export const fetchTargetUpdateTimeline = async payload => {
  return await axios.post(
    '/tasi/entity/target/mapping/history/select/v1',
    payload
  )
}

export const fetchClassifiedRoles = async payload => {
  return await axios.post('admin/workforce/asset_type/role/list', payload)
}

export const additionalFieldUpdate = async payload => {
  return await axios.post('/activity/search/additional/fields/update', payload)
}

export const fetchTataPlaySummary = async payload => {
  return await axios.post('tata/play/user/summary/list', payload)
}

export const fetchTataPlayNetChurnList = async payload => {
  return await axios.post('tata/play/user/summary/net_churn/list', payload)
}

export const fetchTataPlayNetAddList = async payload => {
  return await axios.post('tata/play/user/summary/net_adds/list', payload)
}

export const fetchSIPPenUtil = async payload => {
  return await axios.post('/tasi/sip/report/pen/util/select', payload)
}

export const fetchSipPayoutManagerPredictionDetails = async payload => {
  return await axios.post('/tasi/sip/report/pen/util/payout/select', payload)
}

export const fetchSipPayoutReporteesPredictionDetails = async payload => {
  return await axios.post('/tasi/sip/report/pen/util/payout/select', payload)
}

export const fetchSipPayoutManagerDetails = async payload => {
  return await axios.post('/tasi/sip/report/manager/select', payload)
}

export const fetchSipDynamicManagerPredictionDetails = async payload => {
  return await axios.post('/tasi/sip/report/prediction/summary/select', payload)
}

export const fetchSipDynamicReporteesPredictionDetails = async payload => {
  return await axios.post('/tasi/sip/report/prediction/select', payload)
}

export const fetchTataPlayChurnWidget = async payload => {
  return await axios.post('tata/play/user/summary/gross/churn/widget_value', payload)
}

export const fetchTataPlayAddWidget = async payload => {
  return await axios.post('tata/play/user/summary/gross/add/widget_value', payload)
}

export const fetchTataPlayOpenBaseList = async payload => {
  return await axios.post('tata/play/user/summary/opening/base/list', payload)
}

export const fetchTataPlayCloseBaseList  = async payload => {
  return await axios.post('tata/play/user/summary/closing/base/list', payload)
}

export const fetchFilterOptionsforPeriodicSummary  = async payload => {
  return await axios.post('/tasi/sip/dynamic/filters/select', payload)
}

export const fetchSipPayoutTBCreatorFilterOptions = async payload => {
  return await axios.post('/tasi/sip/payout/report/resource/select', payload)
}

export const fetchSipWidgetAccountDetails = async payload => {
  return await axios.post('/tasi/sip/dynamic/report/accounts/select', payload)
}

export const fetchSipWidgetSARoleList = async payload => {
  return await axios.post('/tasi/sip/role/list/select', payload)
}

export const fetchSipWidgetDynamicMonthWiseData = async payload => {
  return await axios.post('tasi/sip/dynamic/report/month/select', payload)
}

export const fetchSipWidgetAccountCodeList = async payload => {
  return await axios.post('tasi/sip/dynamic/asset/accounts/list', payload)
}

export const fetchSipWidgetAccountMonthWiseData = async payload => {
  return await axios.post('tasi/sip/dynamic/report/accounts/months/select', payload)
}

export const fetchSipDynamicWidgetManagerDetails = async payload => {
  return await axios.post('tasi/sip/dynamic/manager/details', payload)
}

export const fetchSipDynamicWidgetManagerDetailsQuaterly = async payload => {
  return await axios.post('tasi/sip/dynamic/manager/details/quaterly', payload)
}

export const fetchSipDynamicWidgetReporteeDetailsQuaterly = async payload => {
  return await axios.post('tasi/sip/dynamic/report/select/v1', payload)
}