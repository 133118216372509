import axios from './axios_api';
import * as url from './api.constant';

/// searched user list--------------
export const loadChatParticipants = async payload => {
  return await axios.post(url.URL_ACTIVITY_PARTICIPANT_LIST, payload);
};
export const loadUserChatParticipants = async payload => {
  return await axios.post(url.URL_USER_CHAT_PARTICIPANTS, payload);
};
export const loadExistChatCheck = async payload => {
  return await axios.post(url.URL_CHAT_EXIST_CHECK, payload);
};
