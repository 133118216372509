import { Auth } from 'aws-amplify'
import axios from 'axios'
import { LS_LOGIN_MODE } from '../components/login/login.util'
import { AuthMode } from '../utils/common.util'
import {
  AUTH_C_CODE,
  AUTH_E_CODE,
  AUTH_P_CODE,
  AUTH_SESSION_CHECK,
  AUTH_USER_CG_TOKEN_KEY,
  IS_EXTERNAL,
  IS_WEBLOGIN_LINK,
} from '../utils/constant'

const instance = axios.create({
  baseURL: process.env.REACT_APP_AXIOS_EXT_URL,
})

instance.interceptors.request.use(
  config => {
    if (sessionStorage.getItem(AUTH_SESSION_CHECK) === 'true') {
      try {
        return Auth.currentSession()
          .then(session => {
            let accessToken = session.getAccessToken()
            if (accessToken) {
              config.headers.accessToken = accessToken.getJwtToken()
              localStorage.setItem(
                AUTH_USER_CG_TOKEN_KEY,
                accessToken.getJwtToken()
              )
              config.headers['x-grene-auth-flag'] = 2
              if (AuthMode() === LS_LOGIN_MODE.EMAIL) {
                config.headers['x-grene-e-flag'] = 1
                config.headers['x-grene-e'] = localStorage.getItem(AUTH_E_CODE)
              } else if (AuthMode() === LS_LOGIN_MODE.AZURE_AD) {
                config.headers['x-grene-e-flag'] = 1
                config.headers['x-grene-ad-flag'] = 1
                config.headers['x-grene-e'] = localStorage.getItem(AUTH_E_CODE)
              } else {
                config.headers['x-grene-c-code'] = localStorage
                  .getItem(AUTH_C_CODE)
                  .replace('+', '')
                config.headers['x-grene-p-code'] =
                  localStorage.getItem(AUTH_P_CODE)
              }
              return config
            } else {
              throw new Error('Session Expired!')
            }
          })
          .catch(err => {
            console.log(['Auth Session Error', err])
            throw new Error(err.message)
          })
      } catch (err) {
        console.log(err)
        throw new Error('Session Expired!')
      }
    } else if (localStorage.getItem(IS_EXTERNAL) === 'true') {
      config.headers['x-grene-auth-flag'] = 1
      return config
    } else if (localStorage.getItem(IS_WEBLOGIN_LINK) === 'true') {
      // console.log('web-login')
      config.headers['x-grene-auth-flag'] = 1
      return config
    } else {
      localStorage.clear()
      sessionStorage.clear()
      window.location.replace(`${window.location.origin}/login`)
    }
  },
  error => {
    console.log('[AXIOS] Err:', error)
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  config => {
    if (config.data.status === -3205) {
      const {
        response: { message },
      } = config.data
      if (message.name === 'TokenExpiredError') {
        localStorage.clear()
        sessionStorage.clear()
        window.location.replace(`${window.location.origin}/login`)
      }
    }
    return config
  },
  error => {
    if (Number(error.response.status) === 403) {
      localStorage.clear()
      sessionStorage.clear()
      window.location.replace(`${window.location.origin}/login`)
    } else {
      console.log(error)
      return error
    }
  }
)

export default instance
