import { extendTheme } from '@chakra-ui/react'
import colors from './colors'
import { ButtonStyle } from './components/buttonStyle'
import { TableStyle } from './components/tableStyle'

const Lttstheme = extendTheme({
  styles: {
    global: (props) => {
      return ({
      body: {
        bg: '#F5F4F7',
        color: '#000',
        fontWeight: 400,
        fontSize: '14px',
      },
      p: {
        color: '#7F838A',
        marginBottom: '0px',
      },
    })},
  },
  components: {
    Button: ButtonStyle,
    Table: TableStyle,
  },
  fonts:{
    heading: "Open Sans",
    body: "Open Sans",
  },
  colors:{
    secondary: '#F5F4F7',
    amber: '#DE4848',
    brand: {
      800: '#003f72',
      900: '#707070',
      600: '#4DAF89',
    },
    white: '#FFFFFF',
  },
})
export default Lttstheme
