import axios from './axios_api';
import * as url from './api.constant';
export const loadRepositoryList = async payload => {
  return await axios.post(url.URL_FETCH_REPO_LIST, payload);
};
export const loadFolderContent = async payload => {
  return await axios.post(url.URL_FOLDER_CONTENT, payload);
};
export const removeFolderContent = async payload => {
  return await axios.post(url.URL_DOC_REMOVE, payload);
};
export const createNewFolder = async payload => {
  return await axios.post(url.URL_CREATE_FOLDER, payload);
};

export const renameFolder = async payload => {
  return await axios.post(url.URL_RENAME_FOLDER, payload);
};
export const loadSharedFolderUsers = async payload => {
  return await axios.post(url.URL_SHARED_FOLDER_USER, payload);
};
export const searchRoleContacts = async payload => {
  return await axios.post(url.URL_SEARCH_ROLE_USER, payload);
};
export const searchOrgContacts = async payload => {
  return await axios.post(url.URL_SEARCH_ORG_USER_LIST, payload);
};
export const searchWorkforceContacts = async payload => {
  return await axios.post(url.URL_SEARCH_WORKFORCE_USER_LIST, payload);
};
export const setWorkforceRoleAccess = async payload => {
  return await axios.post(url.URL_WORKFORCE_ROLE_ACCESS_SET, payload);
};
export const shareRepoUser = async payload => {
  return await axios.post(url.URL_SHARE_USER_DOCS, payload);
};
export const loadSearchedDocs = async payload => {
  return await axios.post(url.URL_SEARCH_DOCS, payload);
};
export const removeUserShareDoc = async payload => {
  return await axios.post('/drs/doc-repo/remove/user/access', payload);
};
export const docRepoPreviewInsert = async payload => {
	return await axios.post(
		'/admin/document/repository/asset/preview/transaction/insert',
		payload
	)
}
export const docRepoPreviewList = async payload => {
	return await axios.post(
		'/admin/document/repository/asset/preview/transaction/list',
		payload
	)
}
