import axios from './axios_api';
import * as url from './api.constant';

export const loadAddLeave = async payload => {
  return await axios.post(url.URL_LEAVE_ADD, payload);
};
export const loadLeaveList = async payload => {
  return await axios.post(url.URL_LEAVE_LIST, payload);
};
export const loadLeaveEdit = async payload => {
  return await axios.post(url.URL_LEAVE_EDIT, payload);
};
export const loadLeaveDelete = async payload => {
  return await axios.post(url.URL_LEAVE_DELETE, payload);
};
