export const URL_TIMELINE_MESSAGES = `activity/timeline/list`;
export const URL_ACTIVITY_PARTICIPANT_LIST = '/activity/participant/list';

export const URL_SET_PARTICIPANTS = 'activity/participant/access/set';
export const URL_REMOVE_PARTICIPANT = 'activity/participant/access/reset';
export const URL_SET_ACTIVE_OWNER = 'activity/owner/flag/set';
export const URL_USER_CHAT_PARTICIPANTS = 'asset/access/chat/list';
export const URL_CHAT_EXIST_CHECK = '/activity/target/chat';
export const URL_REPORT_LIST = '/analytics/report/list';
export const URL_SAVED_EXPORT_FILTER_LIST = '/report/filter/list';
export const URL_SAVE_EXPORT_FILTER = '/report/filter/set';
export const URL_GET_APPLICATION_LIST = '/analytics/application/list';
export const URL_GET_EXPORT_FILTER_OPTIONS = '/analytics/report/access/list/v1';
export const URL_GET_FILTER_LIST = '/analytics/widget/tag_type/filter/list';
export const URL_ADD_EXPORT_REPORT = '/report/add';
export const URL_REMOVE_SAVED_REPORT = '/report/filter/delete';
export const URL_GET_FORMS_LIST = '/form/access/search/list';
export const URL_GET_FORM_FIELD_LIST = 'form/access/global/entry/collection';
export const URL_REPORT_FILTER_OPTION = '/analytics/report/access/list/v1';
export const URL_REPORT_PROCESS_List = 'admin/category/tagtype/list';
export const URL_ADD_REPORT = '/report/add';
///-leave
export const URL_LEAVE_LIST = '/asset/leave/list';
export const URL_LEAVE_EDIT = '/asset/leave/alter';
export const URL_LEAVE_ADD = '/asset/leave/set';
export const URL_LEAVE_DELETE = '/asset/leave/reset';
///-Team
export const URL_TEAM_MEMBERS = '/admin/manger/assets/list/V1';
////- Vidm
export const URL_VIDM_LIST = '/activity/vidm-data/list';

/////-repository
export const URL_FETCH_REPO_LIST = '/drs/doc-repo/user/access/list';
export const URL_FOLDER_CONTENT = '/drs/doc-repo/list/select';
export const URL_DOC_REMOVE = '/drs/doc-repo/file/delete';
export const URL_CREATE_FOLDER = '/drs/doc-repo/add';
export const URL_RENAME_FOLDER = '/drs/doc-repo/folder/update';
export const URL_SHARED_FOLDER_USER = '/drs/doc-repo/users/list';
export const URL_SEARCH_ROLE_USER = 'admin/workforce/asset_type/role/list';
export const URL_SEARCH_ORG_USER_LIST =
  'admin/organization/coworker_contact/list';
export const URL_SEARCH_WORKFORCE_USER_LIST = 'admin/account/workforce/list';
export const URL_WORKFORCE_ROLE_ACCESS_SET =
  '/drs/doc-repo/workforce-role/access/set';
export const URL_SHARE_USER_DOCS = '/drs/doc-repo/add/user/access';
export const URL_SEARCH_DOCS = '/drs/doc-repo/asset/mapping/search';

//// ***Workhours*** ////
export const ARP_ASSET_ALTER = '/arp/asset/inline/alter';
