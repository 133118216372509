import { extendTheme } from '@chakra-ui/react'
import colors from './colors'
import { ButtonStyle } from './components/buttonStyle'
import { TableStyle } from './components/tableStyle'

const theme = extendTheme({
	styles: {
		global: props => {
			return {
				body: {
					bg: '#F5F4F7',
					color: '#000',
					fontWeight: 400,
					fontSize: '14px',
				},
				p: {
					color: '#7F838A',
					marginBottom: '0px',
				},
			}
		},
	},
	components: {
		Button: ButtonStyle,
		Table: TableStyle,
		Tabs: {
			variants: {
				solid: {
					tab: {
						_selected: {
							color: '#4daf89 !important', // Set the text color of the selected tab
							borderBottom: '2px solid #4daf89',
						},
					},
				},
			},
		},
	},
	fonts: {
		heading: !!localStorage.getItem('font')
			? localStorage.getItem('font')
			: 'Roboto',
		body: !!localStorage.getItem('font')
			? localStorage.getItem('font')
			: 'Roboto',
	},
	colors: {
		secondary: '#F5F4F7',
		amber: '#DE4848',
		brand: {
			800: !!localStorage.getItem('color')
				? localStorage.getItem('color')
				: '#4DAF89',
			900: '#707070',
			600: '#4DAF89',
		},
		white: '#FFFFFF',
	},
})
export default theme
