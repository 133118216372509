import axios from './axios_api'
import axiosIp from './axios_ip'

export const loadQueueList = async payload => {
  return await axios.post('asset/access/queue/list', payload)
}

export const loadFileFilterList = async payload => {
  return await axios.post('/activity/search/mapping/select', payload)
}

export const loadActivityCounts = async payload => {
  return await axios.post('/activity/fetch/counts/list', payload)
}

export const loadUserIpAddress = async () => {
  return await axiosIp.get('?format=json')
}

export const loadS3Creds = async payload => {
  return await axios.post('aws_resources/credentials/fetch', payload)
}

//update mention unreads
export const updateMentionUnreads = async payload => {
  return await axios.post('activity/asset/mention/count/update', payload)
}

//mention send mail
export const onMentionSendMail = async payload => {
  return await axios.post('activity/timeline/mentions/send_email/v1', payload)
}

//fetch slots list for slot datatype
export const loadSlotsList = async payload => {
  return await axios.post('admin/organization/account/list', payload)
}

//fetch file reference
export const loadFileReferences = async payload => {
  return await axios.post('activity/access/folder/list', payload)
}

//to get all user list:--
export const loadCoworkerList = async payload => {
  return await axios.post('admin/organization/coworker_contact/list', payload)
}

//to get all user list:--
export const toGetBucketName = async payload => {
  return await axios.post('common/bucket/master/select', payload)
}

///url/parameters/lookup
export const loadUrlParamsLookup = async payload => {
  return await axios.post('url/parameters/lookup', payload)
}

export const loadRoleList = async payload => {
  return await axios.post('admin/workforce/asset_type/mapping/select', payload)
}

export const loadAssetTypeList = async payload => {
  return await axios.post('asset_type/access/workforce/list', payload)
}

export const createCustomerAsset = async payload => {
  return await axios.post('admin/create/customer/asset', payload)
}

