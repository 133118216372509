import axios from './axios_api'

export const loadOrganiationList = async payload => {
  return await axios.post(
    '/asset/phonenumber/access/organization/list/v2',
    payload
  )
}

export const loadAssetInlineCollection = async payload => {
  return await axios.post('/asset/inline/collection', payload)
}

export const loadAwsBucketName = async payload => {
  return await axios.post('/aws-bucket/name/list', payload)
}

//Load users based on mentions comments
export const loadUsersOnSearch = async payload => {
  return await axios.post('/admin/asset/employee_desk/search', payload)
}

//Load asset auth token
export const loadAssetAuthToken = async payload => {
  return await axios.post('/asset/link/set/v1', payload)
}

export const addTimeSheetData = async payload => {
  return await axios.post('asset/timesheet/add', payload)
}

// reset UserName Password
export const resetPassword = async payload => {
  return await axios.post('asset/mentions-email/pwd/set', payload)
}

export const loadChatList = async payload => {
  return await axios.post('asset/access/chat/list', payload)
}

export const initiateSwipe = async payload => {
  return await axios.post('asset/swipe', payload)
}

export const loadChatCount = async payload => {
  return await axios.post('asset/access/counts/list/v1', payload)
}

export const loadMonthlyGScore = async payload => {
  return await axios.post(
    'admin/asset/monthly/summary/transaction/list',
    payload
  )
}

export const loadMonthlyWorkflowList = async payload => {
  return await axios.post('admin/asset/gamification/transaction/list', payload)
}

export const loadOverallGScore = async payload => {
  return await axios.post('admin/asset/summary/transaction/list', payload)
}

//leave managemnet apis
export const loadLeaveMappings = async payload => {
  return await axios.post('asset/leave/mapping/select', payload)
}

export const loadBotBetaMapping = async payload => {
  return await axios.post('/arp/bot/beta/asset/mapping/select', payload)
}

export const loadLanguageList = async payload => {
  return await axios.post('admin/common/language/master/list', payload)
}