export const ButtonStyle = {
  // style object for base or default style
  baseStyle: {
    fontWeight: '400',
  },
  variants: {
    tabButtonProfile: {
      color: 'brand.600',
      border: 'none',
      _focus: { boxShadow: 'none' },
    },
  },
  // default values for 'size', 'variant' and 'colorScheme'
  defaultProps: {},
}
