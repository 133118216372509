import axios from './axios_api';

// To save snapshot/baseline of a project
export const saveProjectBaseline = async payload => {
  return await axios.post('/activity/snapshot/list/insert', payload);
};

export const getMappingActivity = async payload => {
  return await axios.post(
    '/asset/account/mapped/list',
    payload
  );
};

// to list % completion based on scheduled date
export const loadScheduledData = async payload => {
  return await axios.post('/activity/list/select/schedule', payload);
};

// to list baseline activities
export const loadBaselineData = async payload => {
  return await axios.post('activity/gantt-chart/snapshot/list', payload);
};
