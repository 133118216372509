import axios from './axios_api';
import * as url from './api.constant';

export const loadExportList = async payload => {
  return await axios.post(url.URL_REPORT_LIST, payload);
};
export const loadGetSavedExportFilter = async payload => {
  return await axios.post(url.URL_SAVED_EXPORT_FILTER_LIST, payload);
};

export const loadSaveExportFilter = async payload => {
  return await axios.post(url.URL_SAVE_EXPORT_FILTER, payload);
};
export const loadGetApplicationList = async payload => {
  return await axios.post(url.URL_GET_APPLICATION_LIST, payload);
};
export const loadGetFilterOptions = async payload => {
  return await axios.post(url.URL_GET_EXPORT_FILTER_OPTIONS, payload);
};
export const loadGetFilterList = async payload => {
  return await axios.post(url.URL_GET_FILTER_LIST, payload);
};
export const loadAddExportReport = async payload => {
  return await axios.post(url.URL_ADD_EXPORT_REPORT, payload);
};
export const loadRemoveExportReport = async payload => {
  return await axios.post(url.URL_REMOVE_SAVED_REPORT, payload);
};
export const loadGetFormsList = async payload => {
  return await axios.post(url.URL_GET_FORMS_LIST, payload);
};
export const loadGetFormFieldOptions = async payload => {
  return await axios.post(url.URL_GET_FORM_FIELD_LIST, payload);
};
